import fetch from '../utils/fetch';


export const region = () => {
    return fetch({
        method: 'GET',
        url: '/h5/region'
    })
}

export const fileUpload = (params:any) => {
    return fetch({
        method: 'POST',
        url: '/h5/file/upload',
        data: params,
        isForm: true
    })
}

export const getWx = (params:any) => {
    return fetch({
        method: 'GET',
        url: '/h5/wechat/signature',
        data: params,
    })
}



export const getAD = (type:any) => {
    return fetch({
        method: 'GET',
        url: `/h5/ad/${type}`,
    })
}


import fetch from '../utils/fetch';


export const login = (params:any) => {
    return fetch({
        method: 'POST',
        url: '/h5/login',
        data: params
    })
}

export const reg = (params:any) => {
    return fetch({
        method: 'POST',
        url: '/h5/reg',
        data: params
    })
}

export const sendCode = (params:any) => {
    return fetch({
        method: 'POST',
        url: '/h5/code',
        data: params
    })
}
export const logout = () => {
    return fetch({
        method: 'DELETE',
        url: '/token/logout'
    })
}

export const logoutWx = () => {
    return fetch({
        method: 'DELETE',
        url: '/h5/user/social/WXMP'
    })
}

export const statusData = {
    salesInvoiceStatus: [
        { label: "待申请", key: "DSQ" },
        { label: "待审核", key: "DSH" },
        { label: "待开票", key: "DKP" },
        { label: "待退票", key: "DTP" },
        { label: "已作废", key: "YZF" },
        { label: "已冲红", key: "YCH" },
        { label: "已拒绝", key: "YJJ" },
        { label: "已开票", key: "YKP" }
    ],
    purchaseInvoiceStatus: [
        { label: "待收票", key: "DSQ" },
        { label: "待审核", key: "DSH" },
        { label: "待开票", key: "DKP" },
        { label: "待退票", key: "DTP" },
        { label: "已作废", key: "YZF" },
        { label: "已冲红", key: "YCH" },
        { label: "已拒绝", key: "YJJ" },
        { label: "已开票", key: "YKP" }
    ],
    salesPayStatus: [
        { label: "未到款", key: "WDK" },
        { label: "部分到款", key: "BFDK" },
        { label: "已全款", key: "YQK" },
        { label: "退款中", key: "TKZ" },
        { label: "已退款", key: "YTK" }
    ],
    purchasePayStatus: [
        { label: "未付款", key: "WDK" },
        { label: "部分付款", key: "BFDK" },
        { label: "已全款", key: "YQK" },
        { label: "退款中", key: "TKZ" },
        { label: "已退款", key: "YTK" }
    ],
    orderStatus: [
        { label: "审核失败", key: "SHSB" },
        { label: "待审核", key: "DSH" },
        { label: "待付款", key: "DFK" },
        { label: "待发货", key: "DFH" },
        { label: "待收货", key: "DSHO" },
        { label: "待完成", key: "DWC" },
        { label: "订单关闭", key: "GB" },
        { label: "订单完成", key: "WC" },
        { label: "待提交", key: "NO" }
    ],
    salesDelivery: [
        { label: "买家自提", key: "MJZT" },
        { label: "平台发货", key: "PTFH" },
        { label: "厂家直发", key: "CJZF" }
    ],
    purchaseDelivery: [
        { label: "买家自提", key: "MJZT", isSo: true },
        { label: "平台自提", key: "PTZT" },
        { label: "平台收货", key: "PTSH" },
        { label: "厂家直发", key: "CJZF", isSo: true }
    ],
    batteriesMaterial: [
        { label: "三元", value: "SY", key: "SY", v: 3.7 },
        { label: "磷酸铁锂", value: "LSTL", key: "LSTL", v: 3.2 },
        { label: "钛酸锂", value: "TSL", key: "TSL", v: 2.8 },
        { label: "锂锰", value: "ML", key: "ML", v: 2.8 },
        { label: "复合锂", value: "FHL", key: "FHL", v: 3.55 },
        { label: "碳酸铁锂", value: "TANSTL", key: "TANSTL", v: 3.7 },
        { label: "钴酸锂", value: "GSL", key: "GSL", v: 3.7 },
        { label: "锰酸锂", value: "MSL", key: "MSL", v: 3.0 },
        { label: "锂亚硫酰氯", value: "LYLXL", key: "LYLXL", v: 3.6 },
        {label: "铅酸", value: "QS", key: "QS", v: 12},
    ],
    qualityTag: [
        { label: "A品", key: "AP" },
        { label: "B品", key: "BP" },
        { label: "梯次", key: "TC" }
    ],
    productTag: [
        { label: "拆机", key: "CJ" },
        { label: "分容配组", key: "PZ" },
        { label: "足容", key: "ZR" },
        { label: "含运费", key: "ZHYF" },
        { label: "有质保", key: "YZB" },
        { label: "平头", key: "PT" },
        { label: "螺丝头", key: "LST" },
    ],
    productCategory: [
        { label: "电芯", value: "DX", key: "DX" },
        { label: "模组", value: "MZ", key: "MZ" },
        { label: "电池包", value: "DCB", key: "DCB" }
    ],
    applyCategory: [
        { label: "储能", value: "CN", key: "CN" },
        { label: "动力", value: "DL", key: "DL" },
        { label: "容量", value: "RL", key: "RL" }
    ],
    structureAppearance: [
        { label: "圆柱形", value: "YZ", key: "YZ" },
        { label: "软包", value: "RB", key: "RB" },
        { label: "方形", value: "FX", key: "FX" },
        { label: "纽扣", value: "NK", key: "NK" }
    ]
};

export const tokeys = status => {
    let arr = statusData[status];
    if (!arr) {
        return [];
    }
    return arr;
};

export const tokey = (key, status) => {
    let arr = statusData[status];
    if (!arr) {
        return "";
    }
    const toText = text => {
        let item = arr.filter(box => box.key === text)[0];
        if (item) {
            return item.label;
        } else {
            return "";
        }
    };
    if (key) {
        if (key.includes(",")) {
            let str = [];
            key.split(",").forEach(box => {
                str.push(toText(box));
            });
            return str.join(",");
        } else {
            return toText(key);
        }
    } else {
        return "";
    }
};

import fetch from '../utils/fetch';


export const rfqSubmit = (params:any) => {
    return fetch({
        method: 'POST',
        url: '/h5/rfq',
        data: params
    })
}


export const rfqOrder = (params:any) => {
    return fetch({
        method: 'POST',
        url: '/h5/rfq/order',
        data: params
    })
}


export const rfqGet = (params:any) => {
    return fetch({
        method: 'GET',
        url: '/h5/user/rfq',
        data: params
    })
}

export const rfqDetail = (params:any) => {
    return fetch({
        method: 'GET',
        url: `/h5/user/rfq/${params.id}`
    })
}



import {
    addOrder,
    orderDetail,
    getOrder,
    alipay,
    wxpay,
    closeOrder,
    confirmOrder,
    refundInvoiceOrder,
    refundOrder
} from '../services/order'


export default {
    namespace: 'order',

    state: {
        dataList: {},
        detailData: {},
    },
    effects: {
        async list({ payload, callback, dispatch }){
            let res = await getOrder(payload);
            let dataList = (res && res.data) || {
                records: [],
                current: 1,
                size: 10,
                pages: 0,
                total: 0
            }
            dataList.records.forEach(item => {

            })
            callback && callback(dataList)
            dispatch({
                type: 'order/reducers/save',
                payload: {
                    dataList
                }
            })
        },
        async detail({ payload, callback, dispatch}){
            let res = await orderDetail(payload);
            let data = (res && res.data) || {};
            callback && callback(data)
            dispatch({
                type: 'order/reducers/save',
                payload: {
                    detailData: data
                }
            })
        },
        async add({ payload, callback, dispatch}){
            let res = await addOrder(payload);
            callback(res)
        },
        async alipay({ payload, callback, dispatch}){
            let res = await alipay(payload);
            callback(res)
        },
        async wxpay({ payload, callback, dispatch}){
            let res = await wxpay(payload);
            callback(res)
        },
        async close({ payload, callback, dispatch}){
            let res = await closeOrder(payload);
            callback(res)
        },
        async confirm({ payload, callback, dispatch}){
            let res = await confirmOrder(payload);
            callback(res)
        },
        async refundInvoice({ payload, callback, dispatch}){
            let res = await refundInvoiceOrder(payload);
            callback(res)
        },
        async refund({ payload, callback, dispatch}){
            let res = await refundOrder(payload);
            callback(res)
        },
    },
    reducers: {
        save(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        }
    },
};

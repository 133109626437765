import fetch from '../utils/fetch';
import config from '../config/config'

export const productGet = (params:any) => {
    return fetch({
        method: 'GET',
        url: '/h5/product',
        data: {
            ...params,
            token: config.token
        }
    })
}


export const productDetail = (params:any) => {
    return fetch({
        method: 'GET',
        url: `/h5/product/${params.id}`,
        data: {
            token: config.token
        }
    })
}


export const productFactory = () => {
    return fetch({
        method: 'GET',
        url: `/h5/product/factory`,
    })
}



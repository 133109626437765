import fetch from '../utils/fetch';


export  const userGet = () => {
    return fetch({
        method: 'GET',
        url: '/h5/user'
    })
}

export  const userService = () => {
    return fetch({
        method: 'GET',
        url: '/h5/user/service'
    })
}


export  const userEdit = (params:any) => {
    return fetch({
        method: 'PATCH',
        url: '/h5/user',
        data: params
    })
}

export  const userStock = (params:any) => {
    return fetch({
        method: 'GET',
        url: '/h5/user/stock',
        data: params
    })
}




import {
    productGet,
    productDetail,
    productFactory
} from '../services/product'
import { tokey } from "../utils/key";
import { tagColor, ellipsis, toCapacity } from "../utils/util";


export default {
    namespace: 'product',

    state: {
        dataList: {
            records: [],
            current: 1,
            size: 10,
            pages: 0,
            total: 0
        },
        detailData: {
            media: [],
            tagList: [],
            tagSelect: [],
            parameter: []
        },
        factory: {
            DEFAULT: []
        },
        productHot: [],
    },
    effects: {
        async list({ payload, callback, dispatch }){
            let res = await productGet(payload);

            let dataList = (res && res.data) || {
                records: [],
                current: 1,
                size: 10,
                pages: 0,
                total: 0
            }
            dataList.records.forEach(item => {
                item.title = ellipsis(item.name + ' ' + item.partNo, 50);
            })
            callback && callback(dataList)
            dispatch({
                type: 'product/reducers/save',
                payload: {
                    dataList
                }
            })
        },
        async detail({ payload, callback, dispatch }){
            let res = await productDetail(payload);
            let detailData = (res && res.data) || {
                media: [],
                tagList: [],
                tagSelect: [],
                parameter: [],
            }
            let media = [];
            detailData.videoFile && media.push(detailData.videoFile)
            if(detailData.imageFileList){
                media = media.concat(detailData.imageFileList.splice(0, 6-media.length));
            }
            detailData.media = media;
            detailData.tagList = detailData.tagList || [];
            detailData.tagList.forEach(box => {
                if(box.color){
                    box.color = tagColor(box.color);
                }
            })
            detailData.tagSelect = [];
            detailData.priceList && detailData.priceList.forEach((item:any) => {
                item.num = item.qty;
                if(!detailData.tagSelect.includes(item.tag)){
                    detailData.tagSelect.push({
                        label: tokey(item.tag,'qualityTag'),
                        value: item.tag
                    })
                }
            })
            detailData.title = detailData.name + ' ' + detailData.partNo;
            let parameter = [];
            if(detailData.partNo){
                parameter.push({
                    name: '产品型号',
                    text: detailData.partNo,
                    pdf: (detailData.pdfImgUrlList && detailData.pdfImgUrlList.length > 0) || false
                })
            }
            if(detailData.productCategory){
                parameter.push({
                    name: '产品类型',
                    text: tokey(detailData.productCategory,'productCategory')
                })
            }
            if(detailData.factory){
                parameter.push({
                    name: '生产厂商',
                    text: detailData.factory
                })
            }
            if(detailData.batteriesMaterial){
                parameter.push({
                    name: '电芯材料',
                    text: tokey(detailData.batteriesMaterial, 'batteriesMaterial')
                })
            }
            if(detailData.capacity){
                parameter.push({
                    name: '标称容量',
                    text: toCapacity(detailData.capacity)
                })
            }
            if(detailData.realCapacity){
                parameter.push({
                    name: '实际容量',
                    text: toCapacity(detailData.realCapacity)
                })
            }
            if(detailData.voltage){
                parameter.push({
                    name: '标称电压',
                    text: detailData.voltage + '（V）'
                })
            }
            if(detailData.finishSize){
                parameter.push({
                    name: '形状结构',
                    text: detailData.finishSize
                })
            }

            if(detailData.applyCategory){
                parameter.push({
                    name: '应用类别',
                    text: detailData.applyCategory
                })
            }
            if(detailData.internalResistance){
                parameter.push({
                    name: '内阻',
                    text: '<='+detailData.internalResistance+'mΩ'
                })
            }
            if(detailData.realInternalResistance){
                parameter.push({
                    name: '实际内阻',
                    text: '<='+detailData.realInternalResistance+'mΩ'
                })
            }
            if(detailData.energy){
                parameter.push({
                    name: '能量',
                    text: detailData.energy+'Wh'
                })
            }
            if(detailData.deliveryVoltage){
                parameter.push({
                    name: '出厂电压',
                    text: detailData.deliveryVoltage+'V'
                })
            }
            if(detailData.chargeVoltage){
                parameter.push({
                    name: '充电电压',
                    text: detailData.chargeVoltage+'V'
                })
            }
            if(detailData.chargeCurrent){
                parameter.push({
                    name: '标准充电倍率',
                    text: detailData.chargeCurrent+'C'
                })
            }
            if(detailData.maxChargeCurrent){
                parameter.push({
                    name: '最大充电倍率',
                    text: detailData.maxChargeCurrent+'C'
                })
            }
            if(detailData.dischargeCurrent){
                parameter.push({
                    name: '标准放电倍率',
                    text: detailData.dischargeCurrent+'C'
                })
            }
            if(detailData.maxDischargeCurrent){
                parameter.push({
                    name: '最大放电倍率',
                    text: detailData.maxDischargeCurrent+'C'
                })
            }
            if(detailData.maxPulseDischarge){
                parameter.push({
                    name: '最大脉冲放电倍率30S',
                    text: detailData.maxPulseDischarge+'C'
                })
            }
            if(detailData.dischargeCutoffVoltage){
                parameter.push({
                    name: '放电截止电压',
                    text: detailData.dischargeCutoffVoltage+'V'
                })
            }
            if(detailData.chargeOperatingTemperature){
                parameter.push({
                    name: '工作温度（充电）',
                    text: detailData.chargeOperatingTemperature
                })
            }
            if(detailData.dischargeOperatingTemperature){
                parameter.push({
                    name: '工作温度（放电）',
                    text: detailData.dischargeOperatingTemperature
                })
            }
            if(detailData.oneStorageTemperature){
                parameter.push({
                    name: '贮存温度（1月内）',
                    text: detailData.oneStorageTemperature
                })
            }
            if(detailData.sixStorageTemperature){
                parameter.push({
                    name: '贮存温度（1~6月）',
                    text: detailData.sixStorageTemperature
                })
            }
            if(detailData.weight){
                parameter.push({
                    name: '重量',
                    text: detailData.weight+'g'
                })
            }
            if(detailData.cycleLife){
                parameter.push({
                    name: '循环寿命',
                    text: detailData.cycleLife+'次'
                })
            }
            if(detailData.applyField){
                parameter.push({
                    name: '适用领域',
                    text: detailData.applyField
                })
            }
            if(detailData.moduleSerial || detailData.moduleParallel){
                parameter.push({
                    name: '模组串并数',
                    text: `${
                        detailData.moduleSerial?detailData.moduleSerial+'串':''
                    } ${
                        detailData.moduleParallel?detailData.moduleParallel+'并':''
                    }`
                })
            }
            detailData.parameter = parameter;

            dispatch({
                type: 'product/reducers/save',
                payload: {
                    detailData
                }
            })
            callback && callback(detailData)
        },
        async factory({ payload, callback, dispatch }){
            let res = await productFactory();
            let factory = (res && res.data) || {
                DEFAULT: []
            }
            dispatch({
                type: 'product/reducers/save',
                payload: {
                    factory
                }
            })
        },
        async hot({ payload, callback, dispatch }){
            let res = await productGet(payload);

            let dataList = (res && res.data) || {
                records: []
            }
            dataList.records.forEach(item => {
                item.title = ellipsis(item.name + item.partNo, 40);
            })
            dispatch({
                type: 'product/reducers/save',
                payload: {
                    productHot: dataList.records
                }
            })
        },
    },
    reducers: {
        save(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        }
    },
};


import {
    region,
    fileUpload,
    getWx,
    getAD,
} from '../services/common'



export default {
    namespace: 'common',

    state: {
        region: [],
        adList: [],
    },
    effects: {
        async region({callback, dispatch}){
            let res = await region();
            let data = (res && res.data) || [];
            data.forEach(item => {
                item.label = item.name;
                item.value = item.id;
                item.children && item.children.forEach(itemtwo => {
                    itemtwo.label = itemtwo.name;
                    itemtwo.value = itemtwo.id;
                    itemtwo.children && itemtwo.children.forEach(itemthree => {
                        itemthree.label = itemthree.name;
                        itemthree.value = itemthree.id;
                    })
                })
            })
            dispatch({
                type: 'common/reducers/save',
                payload: {
                    region: data
                }
            })
            callback && callback(res)
        },
        async ad({payload, callback, dispatch}){
            let res = await getAD(payload.type || '');
            dispatch({
                type: 'common/reducers/save',
                payload: {
                    adList: (res && res.data) || []
                }
            });
            callback && callback(res)
        },
        async fileUp({ payload, callback, dispatch}){
            let res = await fileUpload(payload);
            callback && callback(res)
        },
        async wx({payload, callback, dispatch}){
            let win:any = window;
            let res = await getWx(payload)
            let json = (res && res.data) || {};
            win.wx.config({
                ...json,
                jsApiList: ['updateAppMessageShareData','updateTimelineShareData']
            })
            win.wx.ready(() => {
                callback && callback()
            });
        }
    },
    reducers: {
        save(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        }
    },
};


import {
    newGet,
    newTag,
    newDetail,
    //newTopRecommend
} from '../services/news'

import { tagColor, ellipsis } from "../utils/util";

export default {
    namespace: 'news',

    state: {
        dataList: {
            records: [],
            current: 1,
            size: 10,
            pages: 0,
            total: 0
        },
        itemData: {},
        tags: [],
        recommend: []
    },
    effects: {
        async list({ payload, callback, dispatch }){
            let res = await newGet(payload);

            let dataList = (res && res.data) || {
                records: [],
                current: 1,
                size: 10,
                pages: 0,
                total: 0
            }
            dataList.records.forEach(item => {
                item.month = item.createTime ? item.createTime.split('-')[1] : '00';
                item.day = item.createTime ? item.createTime.split('-')[2].split(' ')[0] : '00';
                item.img = item.imgFile ? item.imgFile.url : 'https://img.dianchibao.com/assets/img/default.png';
                item.guideContent = item.guideContent.length > 74 ? ellipsis(item.guideContent,74) : item.guideContent;
                item.tagList = item.tagList || [];
                item.tagList.forEach(box => {
                    if(box.color){
                        box.color = tagColor(box.color);
                    }
                })
            })
            callback && callback(dataList)
            dispatch({
                type: 'news/reducers/save',
                payload: {
                    dataList
                }
            })
        },
        async tag({ payload, callback, dispatch }){
            let res = await newTag(payload);
            let tags = (res && res.data) || []
            tags.forEach(box => {
                if(box.color){
                    box.color = tagColor(box.color);
                }
            })
            callback && callback(tags)
            dispatch({
                type: 'news/reducers/save',
                payload: {
                    tags
                }
            })
        },
        async detail({ payload, callback, dispatch }){
            let res = await newDetail(payload);
            dispatch({
                type: 'news/reducers/save',
                payload: {
                    itemData: (res && res.data) || {}
                }
            })
            callback && callback(res)
        },
        async recommend({ payload, callback, dispatch }){
            let res = await newGet(payload);
            let recommend = (res && res.data) || {
                records: []
            }
            recommend.records.forEach(item => {
                item.month = item.createTime ? item.createTime.split('-')[1] : '00';
                item.day = item.createTime ? item.createTime.split('-')[2].split(' ')[0] : '00';
                item.img = item.imgFile ? item.imgFile.url : 'https://img.dianchibao.com/assets/img/default.png';
                item.guideContent = item.guideContent.length > 74 ? ellipsis(item.guideContent,74) : item.guideContent;
                item.tagList = item.tagList || [];
                item.tagList.forEach(box => {
                    if(box.color){
                        box.color = tagColor(box.color);
                    }
                })
            })
            dispatch({
                type: 'news/reducers/save',
                payload: {
                    recommend: recommend.records
                }
            })
        }
    },
    reducers: {
        save(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        }
    },
};

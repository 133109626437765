
import {
    rfqSubmit,
    rfqOrder,
    rfqGet,
    rfqDetail
} from '../services/rfq'


export default {
    namespace: 'rfq',

    state: {
        dataList: {},
        detailData: {},
    },
    effects: {
        async list({ payload, callback, dispatch }){
            let res = await rfqGet(payload);
            let dataList = (res && res.data) || {
                records: [],
                current: 1,
                size: 10,
                pages: 0,
                total: 0
            }
            dataList.records.forEach(item => {

            })
            callback && callback(dataList)
            dispatch({
                type: 'rfq/reducers/save',
                payload: {
                    dataList
                }
            })
        },
        async detail({ payload, callback, dispatch}){
            let res = await rfqDetail(payload);
            let data = (res && res.data) || {};

            callback && callback(data)
            dispatch({
                type: 'rfq/reducers/save',
                payload: {
                    detailData: data
                }
            })
        },
        async submit({ payload, callback, dispatch}){
            let res = await rfqSubmit(payload);
            callback(res)
        },
        async order({ payload, callback, dispatch}){
            let res = await rfqOrder(payload);
            callback(res)
        },

    },
    reducers: {
        save(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        }
    },
};

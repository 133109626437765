import React, { createElement } from "react";
import { BrowserRouter as Router, Route, Switch, RouteProps } from "react-router-dom";

import Loadable from 'react-loadable';
import { Icon } from 'antd-mobile';

import config from '../config/config'


interface UserRouteProps extends RouteProps{
    routerData: any
};

const routerData:any = config.routerData;


const dynamicWrapper = (path:string) => {
    return Loadable({
        loader: () => {
            return import('../'+path).then(raw => {
                const Component = raw.default || raw;
                return (props: UserRouteProps) =>
                    createElement(Component, {
                        ...props,
                        routerData
                    });
            })
        },
        loading: () => (
            <div className="page-loading-warp">
                <Icon type="loading" size="lg" color="#666" />
                <span className="text">加载中...</span>
            </div>
        )
    });
}

const toComponent = (data) => {
    data.forEach(item => {
        item.component = dynamicWrapper(item.component)
        if(item.routes){
            toComponent(item.routes)
        }
    });
}
toComponent(routerData)

const Routes = () => {
    return (
        <Router>
            <Switch>
                {routerData.map((item, index) => (
                    <Route key={index} path={item.path} render={(props) => (
                        <item.component {...props} routes={item.routes}/>
                    )} />
                ))}
            </Switch>
        </Router>
    );
}



export default Routes;

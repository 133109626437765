import fetch from '../utils/fetch';


export const newGet = (params:any) => {
    return fetch({
        method: 'GET',
        url: '/h5/news',
        data: params
    })
}


export const newDetail = (params:any) => {
    return fetch({
        method: 'GET',
        url: `/h5/news/${params.id}`
    })
}


export const newTag = (params:any) => {
    return fetch({
        method: 'GET',
        url: '/h5/news/tag',
        data: params
    })
}

export const newTopRecommend = (params:any) => {
    return fetch({
        method: 'GET',
        url: '/h5/news/top-recommend',
        data: params
    })
}



import fetch from '../utils/fetch';


export const invoiceGet = (params:any) => {
    return fetch({
        method: 'GET',
        url: '/h5/user/invoice',
        data: params || {}
    })
}


export const invoiceAdd = (params:any) => {
    return fetch({
        method: 'POST',
        url: '/h5/user/invoice',
        data: params
    })
}



export const invoiceEdit = (params:any) => {
    return fetch({
        method: 'PUT',
        url: '/h5/user/invoice',
        data: params
    })
}


export const invoiceDel = (params:any) => {
    return fetch({
        method: 'DELETE',
        url: `/h5/user/invoice/${params.id}`
    })
}

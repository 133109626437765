import fetch from '../utils/fetch';


export const supplierApply = (params:any) => {
    return fetch({
        method: 'POST',
        url: '/h5/supplier/apply',
        data: params
    })
}


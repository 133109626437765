import {
    login,
    reg,
    logout,
    logoutWx,
    sendCode
} from '../services/auth'

import {
    userGet,
    userEdit,
    userService,
    userStock
} from '../services/user'


import { setCookie, getCookie, delCookie, isWx } from '../utils/util';

export default {
    namespace: 'user',
    state: {
        currentUser:  {id: 1},
        service: {},
        userStock: {
            records: []
        }
    },
    effects: {
        async get({ callback, dispatch}){
            if(getCookie('token')){
                let res = await userGet();
                let data = (res && res.data) || {};
                data.headImgUrl = data.headImgUrl || 'https://img.dianchibao.com/h5/avatar.png'
                dispatch({
                    type: 'user/reducers/save',
                    payload: {
                        currentUser: data
                    }
                })
                callback && callback(res)
                let service = await userService();
                dispatch({
                    type: 'user/reducers/save',
                    payload: {
                        service: (service && service.data) || {}
                    }
                })
                let exp = getCookie('token-exp');
                let old:any = new Date(Number(exp));
                let news:any = new Date();
                let s = old.getTime() - news.getTime();
                setTimeout(() => {
                    delCookie('token');
                    dispatch({
                        type: 'user/reducers/save',
                        payload: {
                            currentUser: {}
                        }
                    })
                    window.location.reload()
                }, s)
            } else {
                dispatch({
                    type: 'user/reducers/save',
                    payload: {
                        currentUser: {}
                    }
                })
            }
        },
        async login({ payload, callback, dispatch}){
            let res = await login(payload);
            if(res && res.code === 0){
                if(typeof res.data === 'string'){
                    callback && callback(res)
                } else {
                    setCookie('token', res.data.accessToken, res.data.expiresIn * 1000);
                    let exp:any = new Date();
                    setCookie('token-exp',exp.getTime() + res.data.expiresIn * 1000, res.data.expiresIn * 1000);
                    dispatch({
                        type: 'user/get',
                        callback: () => {
                            callback && callback(res)
                        }
                    })
                }

            }
        },
        async reg({ payload, callback, dispatch}){
            let res = await reg(payload);
            if(res && res.code === 0){
                setCookie('token', res.data.accessToken, res.data.expiresIn * 1000);
                let exp:any = new Date();
                setCookie('token-exp',exp.getTime() + res.data.expiresIn * 1000, res.data.expiresIn * 1000);
                dispatch({
                    type: 'user/get',
                    callback: () => {
                        callback && callback(res)
                    }
                })
            }
        },
        async code({ payload, callback, dispatch}){
            let res = await sendCode(payload);
            callback && callback(res)
        },
        async edit({ payload, callback, dispatch}){
            let res = await userEdit(payload);
            callback && callback(res)
        },
        async logout({ callback, dispatch}){
            if(isWx()){
                await logoutWx();
            }
            let res = await logout();
            delCookie('token');
            dispatch({
                type: 'user/reducers/save',
                payload: {
                    currentUser: {}
                }
            })
            callback && callback(res)
        },
        async stock({ payload, callback, dispatch }){
            let res = await userStock(payload);

            let dataList = (res && res.data) || {
                records: [],
                current: 1,
                size: 10,
                pages: 0,
                total: 0
            }
            dataList.records.forEach(item => {

            })
            callback && callback(dataList)
            dispatch({
                type: 'user/reducers/save',
                payload: {
                    userStock: dataList
                }
            })
        },
    },
    reducers: {
        save(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        }
    },
};


export default [
    {
        path: '/',
        component: 'layouts/BasicLayout',
        routes: [
            {
                path: '/',
                name: '首页',
                component: 'pages/Home/Home',
            },
            {
                path: '/home',
                redirect: '/'
            },
            {
                path: '/login',
                name: '登录',
                routes: [
                    {
                        path: '/login',
                        name: '登录',
                        component: 'pages/Login/Login'
                    }
                ]
            },
            {
                path: '/register',
                name: '注册',
                routes: [
                    {
                        path: '/register',
                        name: '注册',
                        component: 'pages/Register/Register'
                    }
                ]
            },
            {
                path: '/bind',
                name: '绑定',
                routes: [
                    {
                        path: '/bind',
                        name: '绑定',
                        component: 'pages/Bind/Bind'
                    }
                ]
            },
            {
                path: '/agreement',
                name: '用户协议',
                routes: [
                    {
                        path: '/agreement',
                        name: '用户协议',
                        component: 'pages/Agreement/Agreement'
                    }
                ]
            },
            {
                path: '/product',
                name: '产品',
                routes: [
                    {
                        path: '/product',
                        name: '找产品',
                        component: 'pages/Product/Product'
                    },
                    {
                        path: '/product/:id',
                        name: '产品详情',
                        component: 'pages/ProductDetail/ProductDetail'
                    },
                    {
                        path: '/specification/:id',
                        name: '产品规格书',
                        component: 'pages/Specification/Specification'
                    }
                ]
            },
            {
                path: '/settle-accounts',
                name: '结算',
                component: 'pages/SettleAccounts/SettleAccounts',
                auth: true,
            },
            {
                path: '/pay/:id',
                name: '支付',
                component: 'pages/Pay/Pay',
                auth: true,
            },
            {
                path: '/search/:name',
                name: '搜索',
                component: 'pages/Search/Search'
            },
            {
                path: '/news',
                name: '资讯',
                routes: [
                    {
                        path: '/news',
                        name: '行业资讯',
                        component: 'pages/News/News'
                    },
                    {
                        path: '/news/:id',
                        name: '资讯详情',
                        component: 'pages/NewsDetail/NewsDetail'
                    }
                ]
            },
            {
                path: '/inquiry',
                name: '快速求购',
                component: 'pages/Inquiry/Inquiry',
            },
            {
                path: '/excess-stock',
                name: '库存变现',
                component: 'pages/ExcessStock/ExcessStock',
            },
            {
                path: '/user',
                name: '用户',
                routes: [
                    {
                        path: '/user',
                        name: '我的',
                        component: 'pages/User/User'
                    },
                    {
                        path: '/user/order',
                        name: '我的订单',
                        component: 'pages/UserOrder/UserOrder',
                        auth: true,
                    },
                    {
                        path: '/user/order/:id',
                        name: '订单详情',
                        component: 'pages/OrderDetail/OrderDetail',
                        auth: true,
                    },
                    {
                        path: '/user/purchase',
                        name: '我的求购',
                        component: 'pages/UserRfq/UserRfq',
                        auth: true,
                    },
                    {
                        path: '/user/stock',
                        name: '我的库存',
                        component: 'pages/UserStock/UserStock',
                        auth: true,
                    },
                    {
                        path: '/user/address',
                        name: '地址信息',
                        component: 'pages/UserAddress/UserAddress',
                        auth: true,
                    },
                    {
                        path: '/user/invoice',
                        name: '发票信息',
                        component: 'pages/UserInvoice/UserInvoice',
                        auth: true,
                    },
                    {
                        path: '/user/account',
                        name: '账户设置',
                        component: 'pages/UserSet/UserSet',
                        auth: true,
                    }
                ]
            },
            {
                path: '*',
                name: '404',
                component: 'pages/Exception/404'
            }
        ]
    }
]

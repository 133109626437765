
import {
    invoiceAdd,
    invoiceDel,
    invoiceEdit,
    invoiceGet
} from '../services/invoice'


export default {
    namespace: 'invoice',

    state: {
        invoiceList: [],

    },
    effects: {
        async get({ payload, callback, dispatch}){
            let res = await invoiceGet(payload);
            dispatch({
                type: 'invoice/reducers/save',
                payload: {
                    invoiceList: (res && res.data) || []
                }
            })
            callback && callback((res && res.data) || [])
        },
        async add({ payload, callback, dispatch}){
            let res = await invoiceAdd(payload);
            callback && callback(res)
        },
        async edit({ payload, callback, dispatch}){
            let res = await invoiceEdit(payload);
            callback && callback(res)
        },
        async del({ payload, callback, dispatch}){
            let res = await invoiceDel(payload);
            callback && callback(res)
        },
    },
    reducers: {
        save(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        }
    },
};

export const getPageTitle = (location:any, routerData: any) => {
    let title = '';
    const getName = (data) => {
        data.forEach(item => {
            if(item.name && item.path === location.pathname){
                title = `${item.name}-电池宝`;
            }
            if(location.pathname.includes('/search/')){
                title = `搜索-电池宝`
            }
            if(location.pathname.includes('/product/')){
                title = `产品详情-电池宝`
            }
            if(location.pathname.includes('/pay/')){
                title = `支付-电池宝`
            }
            if(location.pathname.includes('/user/order/')){
                title = `订单详情-电池宝`
            }
            if(location.pathname.includes('/news/')){
                title = `咨询详情-电池宝`
            }
            if(location.pathname.includes('/specification/')){
                title = `产品规格书-电池宝`
            }
            if(item.routes){
                getName(item.routes)
            }
        });
    }
    getName(routerData)
    return title || '404-电池宝';
}

export const OSnow = () => {
    let agent = navigator.userAgent.toLowerCase();
    let isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    if (
        agent.indexOf("win32") >= 0 ||
        agent.indexOf("wow32") >= 0 ||
        agent.indexOf("win64") >= 0 ||
        agent.indexOf("wow64") >= 0
    ) {
        return 'win'
    }
    if(isMac){
        return 'mac'
    }
}
export const isWx = () => {
    let rst = false
    let ua:any = navigator.userAgent.toLowerCase();
    if (ua.includes("micromessenger")) {
        rst = true;
    }
    return rst;
}
export const toNumFixed = (num, n) => {
    return Math.round(num * n) / n
};


/**
* 价格格式化
*
* @param {*} str
* @returns
*/
export const toPrice = (str) => {
    str = (str || 0).toString().replace(/,/g, "");
    if(str.split(".").length > 1){
        return str.toString().split(".")[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + str.toString().split(".")[1];
    }else{
        return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
};

/**
* 数字乘
*
* @param {*} a
* @param {*} b
* @returns
*/
export const mul = (a, b) => {

    let c = 0,
        d = a ? a.toString() : '0',
        e = b ? b.toString() : '0';
    try {
        c += d.split(".")[1].length;
    } catch (f) {}
    try {
        c += e.split(".")[1].length;
    } catch (f) {}
    return Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c);
};

/**
 * 数字相加
 *
 * @param {*} a
 * @param {*} b
 * @returns
 */
export const addNum = (a, b) => {
    let c, d, e;
    try {
        c = a.toString().split(".")[1].length;
    } catch (f) {
        c = 0;
    }
    try {
        d = b.toString().split(".")[1].length;
    } catch (f) {
        d = 0;
    }
    e = Math.pow(10, Math.max(c, d));
    return (mul(a, e) + mul(b, e)) / e;
};

// 两个浮点数相减
export function accSub(num1,num2){
    var r1, r2, m;
    try{
        r1 = num1.toString().split('.')[1].length;
    }catch(e){
        r1 = 0;
    }
    try{
        r2 = num2.toString().split(".")[1].length;
    }catch(e){
        r2 = 0;
    }
    m = Math.pow(10,Math.max(r1, r2));
    return Math.round(num1 * m - num2 * m) / m;
}

export const toDate = (str:string,s:string='') => {
    let date:any = new Date(str);
    let p = (s:number) => {
        return s < 10 ? '0' + s: s;
    }
    if( s === "CN" ){
        return date.getFullYear() + '年' + p((date.getMonth() + 1)) + '月' + p(date.getDate()) + '日  ' + p(date.getHours()) + ':' + p(date.getMinutes()) + ':' + p(date.getSeconds());
    }else{
        return date.getFullYear() + '-' + p((date.getMonth() + 1)) + '-' + p(date.getDate()) + '  ' + p(date.getHours()) + ':' + p(date.getMinutes()) + ':' + p(date.getSeconds());
    }
};

export const ellipsis = (str:string, len:number) => {
    return str.length > len ? `${str.substring(0, len)}...` : `${str}`;
}
export const delNoNumFloatTo = (value)=>{
    value = value || "";
    value = value.toString();
    let text = value.replace(/[^\d^.^\-^|]/g, "");
    return text;
};

export const delNoNumFloat = (value)=>{
    let text = value.replace(/[^\d^.]/g, "");
    let arr = text.split('.');
    let oneNum = arr.splice(0, 1)[0];

    if(arr.length === 0){
        return oneNum || 0;
    }else{
        return `${oneNum}.${arr.join('')}`
    }
};

export const throttle = (fn, delay:number = 1000) => {
    let lastTime = null;
    let timer = null;
    return function () {
        let curTime = new Date().getTime()
        if (lastTime && curTime < lastTime + delay) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                fn();
                lastTime = curTime
            }, delay)
        } else {
            lastTime = curTime;
            fn();
        }

    }
}

export const toCapacity = (str) => {
    str = delNoNumFloatTo(str);
    let a = (n) => {
        return n>=5? `${n}Ah` : `${mul(n, 1000)}mAh`
    }
    if(str.indexOf("|") > 0){
        let arr = [];
        str.split("|").forEach(item => {
            arr.push(toCapacity(item))
        })
        return arr.join(',')
    }
    if(str.indexOf('-') >= 0){
        return `${a(Number(delNoNumFloat(str.split('-')[0])))}-${a(Number(delNoNumFloat(str.split('-')[1])))}`
    }
    return a(str)
}


export const randomNum = (n: number,m: number) => {
    return Math.floor(Math.random()*(m-n+1)+n);
};

export const randomArr = (arr: any) => {
    return arr[Math.floor(Math.random()*(arr.length))]
}
export const parseParams = (url: string, data: any) => {
    if(!data){
        return url;
    }
    let tempArr = [];
    for (let i in data) {
        let key = encodeURIComponent(i);
        let value = encodeURIComponent(data[i]);
        tempArr.push(key + '=' + value);
    }
    return `${url}?${tempArr.join('&')}`;
}

export const getParams = (url: string) => {
    let json:any = {};
    if (url.indexOf("?") !== -1) {
         let str = url.substr(1);
         let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
            json[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
        }
    }
    return json;
}

export const toUndefined = (json: any) => {
    for(let key in json){
        json[key] = json[key] || '';
    }
    return json;
}

export const getScrollTop = () => {
    let scrollTop = 0;
    if(document.documentElement && document.documentElement.scrollTop){
        scrollTop = document.documentElement.scrollTop;
    } else if(document.body){
        scrollTop = document.body.scrollTop;
    }
    return scrollTop;
}
export const setScrollTop = (num) => {
    if(document.documentElement && document.documentElement.scrollTop){
        document.documentElement.scrollTop = num;
    } else if(document.body){
        document.body.scrollTop = num;
    }
}
export const getClienHeight = () => {
    let clientHeight = 0;
    if(document.documentElement && document.documentElement.clientHeight){
        clientHeight = document.documentElement.clientHeight;
    }
    return clientHeight
}
export const getClienWidth = () => {
    let clientWidth = 0;
    if(document.documentElement && document.documentElement.clientWidth){
        clientWidth = document.documentElement.clientWidth;
    }
    return clientWidth
}
export const getScrollHeight = () => {
    let scrollHeight = 0;
    if(document.documentElement && document.documentElement.scrollHeight){
        scrollHeight = document.documentElement.scrollHeight;
    }
    return scrollHeight
}
export const delSpace = (value)=>{
    return value.replace(/(^\s*)|(\s*$)/g, "");
};
export const delHtml = (value)=>{
    return value.replace(/<[^>]+>/g,"");
};

export const setCookie = (name:string, value:string, s:number) => {
    let exp:any = new Date();
    exp.setTime(exp.getTime() + s);
    document.cookie = `${name}=${escape(value)};expires=${exp.toGMTString()};path=/`;
}
export const getCookie = (name:string) => {
    let arr;
    let reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`);
    arr = document.cookie.match(reg);
    if (arr) {
        return unescape(arr[2]);
    } else {
        return null;
    }
}
export const delCookie = (name:string) => {
    let exp:any = new Date();
    exp.setTime(exp.getTime() - 10000);
    let cval = getCookie(name);
    if (cval != null) document.cookie = `${name}=${cval};expires=${exp.toGMTString()};path=/`;
}

export const tagColor = (key:string) => {
    let a = [{ label: "紫色", key: 'ZISE', bg: '#E8EEFD', color: '#638FF9' },
            { label: "红色", key: 'HONGSE', bg: '#ffece8', color: '#FF8062' },
            { label: "灰色", key: 'HUISE', bg: '#f4f7f9', color: '#849aae' },
            { label: "蓝绿色", key: 'LANLVSE', bg: '#e1f5f8', color: '#39BECD' },
            { label: "绿色", key: 'LVSE', bg: '#e1f5ed', color: '#33BE85' }];

    return a.filter(item => item.key === key)[0] ? a.filter(item => item.key === key)[0] : {bg: '#ddd', color: '#666'};
}

export const initRem = () => {
    const win:any = window;
    const doc:any = document;
    const init = () => {
        let width:any = doc.documentElement.clientWidth
        doc.documentElement.style.fontSize = width / 10 + 'px'
    }
    init();
    win.addEventListener('orientationchange', init)
    win.addEventListener('resize', init)
}

export class MapCache {
    cache: any
    timer: any
    maxCache: number
    constructor() {
        this.cache = new Map();
        this.timer = {};
        this.maxCache = 50;
    }

    get(key) {
        return this.cache.get(key);
    }

    set(key, value, ttl = 60000) {
        // 如果超过最大缓存数, 删除头部的第一个缓存.
        if (this.maxCache > 0 && this.cache.size >= this.maxCache) {
            const deleteKey = [...this.cache.keys()][0];
            this.cache.delete(deleteKey);
            if (this.timer[deleteKey]) {
                clearTimeout(this.timer[deleteKey]);
            }
        }
        this.cache.set(key, value);
        if (ttl > 0) {
            this.timer[key] = setTimeout(() => {
                this.cache.delete(key);
                delete this.timer[key];
            }, ttl);
        }
    }

    delete(key) {
        delete this.timer[key];
        return this.cache.delete(key);
    }

    clear() {
        this.timer = {};
        return this.cache.clear();
    }
}


export const initCanvas = (el, type, width, height, thickness) => {

    let h = 160;
    let w = 50;
    let t = 30;

    let color = '#0660A3';
    let canvas = el;
    canvas.width = 300;
    canvas.height = 300;
    let ctx = canvas.getContext("2d");
    let img = new Image();
    img.onload = () => {
        ctx.drawImage(img, 205, 250, 80, 28);
    }
    img.src = "https://img.dianchibao.com/assets/img/logo-img.png"
    const addLine = (arr) => {
        ctx.moveTo(arr[0][0],arr[0][1]);
        ctx.lineTo(arr[1][0],arr[1][1]);
        ctx.lineWidth = 1;
        ctx.strokeStyle = color;
        ctx.stroke();
    }
    const addTriangle = (arr) => {
        ctx.beginPath();
        ctx.moveTo(arr[0][0],arr[0][1]);
        ctx.lineTo(arr[1][0],arr[1][1]);
        ctx.lineTo(arr[2][0],arr[2][1]);
        ctx.lineWidth = 1;
        ctx.fillStyle = color;
        ctx.fill();
    }
    const addText = (text, arr) => {
        ctx.font = "14px Arial";
        ctx.fillStyle = color;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(text, arr[0],arr[1]);
    }
    const addCircle = (arr, r, c) => {
        ctx.beginPath();
        ctx.arc(arr[0],arr[1],r,0,2*Math.PI);
        ctx.fillStyle = c;
        ctx.fill();
    }
    const addRoundedRect = (x, y, width, height, r)  => {
        ctx.beginPath();
        ctx.moveTo(x + r, y);
        ctx.arcTo(x + width, y, x + width, y + r, r);
        ctx.arcTo(x + width, y + height, x + width - r, y + height, r);
        ctx.arcTo(x, y + height, x, y + height - r, r);
        ctx.arcTo(x, y, x + r, y, r);
        ctx.fillStyle = color;
        ctx.fill();
        ctx.restore();
    }
    const addRounded = (x, y, width, height)  => {
        ctx.beginPath();
        ctx.fillStyle = color;
        ctx.fillRect(x,y,width,height);
    }

    if(type === 'YZ' || type === 'NK'){

        let b = height / thickness;
        w = t * 2;
        h = w * b;
        h = h > 260 ? 260 : h;
        let x1 = 60;
        let y1 = 180;

        let x2 = 300 - 60;
        let y2 = 120;
        addCircle([x2, y2], t, color);
        addCircle([x2, y2], t - 10, "#dddddd");

        addLine([[x2 - t, y2 - t],[x2 - t, y2 - t - t]]);
        addLine([[x2 + t, y2 - t],[x2 + t, y2 - t - t]]);
        addLine([[x2 - t, y2 - t - (t / 2)],[x2 + t, y2 - t - (t / 2)]])
        addTriangle([[x2 - t, y2 - t - (t / 2)],[x2 - t + 5, y2 - t - (t / 2) - 5],[x2 - t + 5, y2 - t - (t / 2) - 5 + 10]]);
        addTriangle([[x2 + t, y2 - t - (t / 2)],[x2 + t - 5, y2 - t - (t / 2) - 5],[x2 + t - 5, y2 - t - (t / 2) - 5 + 10]]);
        addText(`φ${thickness}mm`, [x2, y2 - t * 2 - 10]);

        let sx1 = x1 - w / 2;
        let sy1 = y1 - h / 2;
        addRoundedRect(sx1, sy1 - 20, w, h, 3);
        addRoundedRect(sx1, sy1 - 30, w, 8, 3);
        addRoundedRect(sx1 + (w - (w / 4) * 3) / 2 , sy1 - 22, w - (w / 4), 10, 2);
        addRoundedRect(sx1 + (w - (w / 3) * 2) / 2 , sy1 - 35, w - (w / 3), 5, 2);

        addLine([[sx1, sy1 - 36],[sx1 + w + 20, sy1 - 36]]);
        addLine([[sx1, sy1 + h - 19],[sx1 + w + 20, sy1 + h - 19]]);

        addLine([[sx1 + w + 10, sy1 - 36], [sx1 + w + 10,  (sy1 - 36) + (h / 4) ]]);
        addLine([[sx1 + w + 10, (sy1 - 19) + (h / 4) * 3 ],[sx1 + w + 10,  sy1 + h - 19 ]]);

        addTriangle([[sx1 + w + 10, sy1 - 36],[sx1 + w + 10 - 5, sy1 - 36 + 5],[sx1 + w + 10 + 5, sy1 - 36 + 5]]);
        addTriangle([[sx1 + w + 10, sy1 - 19 + h],[sx1 + w + 10 - 5, sy1 - 19 + h - 5],[sx1 + w + 10 + 5, sy1 - 19 + h - 5]]);

        addText(`${height}`, [sx1 + w + 20, (sy1 - 36) + (h / 2)]);
        addText(`mm`, [sx1 + w + 20, (sy1 - 36) + (h / 2) + 20]);

    }
    if(type === 'FX' || type === 'RB') {
        let b = height / width;
        w = 80;
        t = (thickness / width) * w;
        h = w * b;
        h = h > 250 ? 250 : h;

        let x1 = 90;
        let y1 = 140;

        let x2 = 300 - 60;
        let y2 = y1;

        let sx1 = x1 - w / 2;
        let sx2 = x2 - t / 2;
        let sy1 = y1 - h / 2;
        let sy2 = y2 - h / 2;


        addRounded(sx1, sy1, w, h);
        addLine([[sx1-13,sy1],[sx1-3, sy1]]);
        addLine([[sx1-13,sy1 + h],[sx1-3, sy1 + h]]);

        addLine([[sx1-8,sy1],[sx1-8, sy1 + (h / 4)]]);
        addLine([[sx1-8,sy1 + (h / 4) * 3],[sx1-8, sy1 + h]]);

        addTriangle([[sx1-8,sy1],[sx1-8-5,sy1+5],[sx1-8+5, sy1+5]]);
        addTriangle([[sx1-8,sy1 + h],[sx1-8-5,sy1 + h - 5],[sx1-8+5, sy1 + h - 5]]);

        addLine([[sx1, sy1 + h + 2],[sx1, sy1 + h + 2 + 10]]);
        addLine([[sx1 + w, sy1 + h + 2],[sx1 + w, sy1 + h + 2 + 10]]);

        addLine([[sx1, sy1 + h + 2 + 5],[sx1 + ( w / 4 ), sy1 + h + 2 + 5]]);
        addLine([[sx1 + ( w / 4 ) * 3, sy1 + h + 2 + 5],[sx1 + w, sy1 + h + 2 + 5]]);

        addTriangle([[sx1, sy1 + h + 2 + 5],[sx1 + 5, sy1 + h + 2 + 5 + 5],[sx1 + 5, sy1 + h + 2]]);
        addTriangle([[sx1 + w,sy1 + h + 2 + 5],[sx1 + w - 5, sy1 + h + 2 + 10],[sx1 + w - 5, sy1 + h + 2]]);

        addLine([[sx1 + 12, sy1 - 12],[sx1 + 22, sy1 - 12]]);
        addLine([[sx1 + 17, sy1 - 17],[sx1 + 17, sy1 - 7]]);
        addLine([[sx1 + w - 22, sy1 - 12],[sx1 + w - 10, sy1 - 12]]);

        //addText('高', [sx1 - 13, sy1 + (h / 2) - 15]);
        addText(height, [sx1 - 15, sy1 + h / 2 - 5]);
        addText('mm', [sx1 - 17, sy1 + (h / 2) + 14 - 5]);
        addText(`${width}mm`, [sx1 + (w / 2), sy1 + h + 20]);

        addRounded(sx2, sy2, t, h);
        addText(`${thickness}mm`, [sx2 + 10, sy2 + h + 24]);

        addLine([[sx2, sy2 + h + 2],[sx2, sy2 + h + 12]]);
        addLine([[sx2 + t, sy2 + h + 2],[sx2 + t, sy2 + h + 12]]);
        addLine([[sx2, sy2 + h + 7],[sx2 + t, sy2 + h + 7]]);

        addTriangle([[sx2, sy2 + h + 7],[sx2 + 5, sy2 + h + 12],[sx2 + 5, sy2 + h + 2]]);
        addTriangle([[sx2 + t, sy2 + h +7],[sx2 + t - 5, sy2 + h + 12],[sx2 + t - 5, sy2 + h + 2]]);
    }
}


import {
    supplierApply
} from '../services/supplier'


export default {
    namespace: 'supplier',

    state: {

    },
    effects: {
        async apply({ payload, callback, dispatch}){
            let res = await supplierApply(payload);
            callback(res)
        },
    },
    reducers: {
        save(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        }
    },
};

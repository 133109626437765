import { combineReducers } from "redux";
import { actionsUtil, reducersUtil, reducersLoading } from "../utils/action"

import user from './user'
import news from './news'
import product from './product'
import supplier from './supplier'
import rfq from './rfq'
import common from './common'
import address from './address'
import invoice from './invoice'
import order from './order'




export const Actions = {
    ...actionsUtil(user),
    ...actionsUtil(news),
    ...actionsUtil(product),
    ...actionsUtil(supplier),
    ...actionsUtil(rfq),
    ...actionsUtil(common),
    ...actionsUtil(address),
    ...actionsUtil(invoice),
    ...actionsUtil(order),
};



export const Reducers = combineReducers({
    ...reducersLoading(Actions),
    ...reducersUtil(user),
    ...reducersUtil(news),
    ...reducersUtil(product),
    ...reducersUtil(supplier),
    ...reducersUtil(rfq),
    ...reducersUtil(common),
    ...reducersUtil(address),
    ...reducersUtil(invoice),
    ...reducersUtil(order),
});


import React from 'react';
import ReactDOM from 'react-dom';

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";

import { Reducers, Actions } from "./models/index";

import Routes from './routes/Router';
import * as serviceWorker from './serviceWorker';

import './themes/index.less'


const store = createStore(Reducers, applyMiddleware(({dispatch}) => (next) => (action) => {
    if (action.type.includes('reducer')) {
        return next(action);
    }
    let actions = Actions[action.type];

    const actionsLoading = async () => {
        dispatch({
            type: 'loading/reducers',
            payload: {
                [action.type]: true
            },
        })
        await actions({
            ...action,
            dispatch
        })
        dispatch({
            type: 'loading/reducers',
            payload: {
                [action.type]: false
            },
        })
    }
    return actionsLoading();
}));


ReactDOM.render(
    <Provider store={store}>
        <Routes />
    </Provider>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

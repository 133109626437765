import fetch from '../utils/fetch';


export const shippingAddressGet = (params:any) => {
    return fetch({
        method: 'GET',
        url: '/h5/user/shipping-address',
        data: params || {}
    })
}


export const shippingAddressAdd = (params:any) => {
    return fetch({
        method: 'POST',
        url: '/h5/user/shipping-address',
        data: params
    })
}



export const shippingAddressEdit = (params:any) => {
    return fetch({
        method: 'PUT',
        url: '/h5/user/shipping-address',
        data: params
    })
}


export const shippingAddressDel = (params:any) => {
    return fetch({
        method: 'DELETE',
        url: `/h5/user/shipping-address/${params.id}`
    })
}

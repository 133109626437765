import routerData from './router.config'
import { tokeys, tokey } from '../utils/key';

export default {
    env: process.env.REACT_APP_ENV,
    api: process.env.REACT_APP_API,
    path: process.env.REACT_APP_PATH,
    token: '326f957cbd21811681ab8dab60bbe1778ef8212107f03',
    routerData,
    tokeys,
    tokey
}

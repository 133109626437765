
import {  handleActions } from 'redux-actions';



export const actionsUtil = (data:any) => {
    let actions:any = {};
    for(let key in data.effects){
        actions[`${data.namespace}/${key}`] = data.effects[key];
    }
    return actions;
}

export const reducersUtil = (data:any) => {
    let reducers:any = {};
    for(let key in data.reducers){
        reducers[`${data.namespace}/reducers/${key}`] = data.reducers[key];
    }
    return {
        [data.namespace]: handleActions(reducers,data.state)
    }
}



export const reducersLoading = (data:any) => {
    const state:any = {};

    for(let key in data){
        state[key] = false
    }
    return {
        loading: handleActions({
            'loading/reducers': (state, action) => {
                return {
                    ...state,
                    ...action.payload
                }
            }
        }, state)
    }
}

import 'whatwg-fetch'
import { Toast } from 'antd-mobile';
import config from '../config/config'
import { MapCache, parseParams, getCookie, delCookie } from './util';

const cache = new MapCache();

const isToken = (url:string) => {
    let a = [
        "/h5/index",
        "/h5/code",
        "/h5/login",
        "/h5/reg",
        "/h5/news",
        "/h5/wechat",
        "/h5/ad"
    ].filter(item => url.includes(item));
    return a.length > 0;
}

const errorHandler = (res: any) => {
    Toast.fail(res.msg || '服务器错误，请稍后再试', 1);
    return res;
};
const fetch = async (options) => {
    const defaultOptions = {
        method: 'GET',
        headers: {

        },
        data: {},
        credentials: 'include',
        isForm: false,
        cache: false
    }

    let url = `${config.api}`;
    if(typeof options === 'string'){
        url = `${url}${options}`
        options = {
            ...defaultOptions
        }
    } else {
        url = `${url}${options.url}`;
        options = {
            ...defaultOptions,
            ...options
        }
    }
    let token = getCookie('token');

    if(token && !isToken(url)){
        if( options.method === 'GET'){
            options.data["access_token"] = token
        } else {
            url = `${url}?access_token=${token}`
        }
    }

    if( options.method === 'GET' ){
        url = parseParams(url, options.data)
        let response = cache.get(url);
        if(response){
            return response;
        }
    }else{
        if(options.isForm){
            let formDate = new FormData();
            for(let key in options.data){
                formDate.append(key, options.data[key])
            }
            options.body = formDate;

        }else{
            options.headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json;  charset=utf-8'
            }
            options.body = JSON.stringify(options.data);
        }
    }

    let isCache = options.cache;
    delete options.cache;
    delete options.isForm;
    delete options.data;
    delete options.url;

    const request = async (url, options) => {
        try {
            const response = await window.fetch(url, options);
            if(response.status === 200){
                let jsonData = await response.json();
                if(jsonData.code === 0){
                    if(options.method === 'GET' && isCache){
                        cache.set(url, jsonData);
                    }
                } else {
                    errorHandler(jsonData)
                }

                return jsonData;
            } else if( response.status === 401 ){
                delCookie('token');
            } else {
                return errorHandler({
                    code: 1
                })
            }
        } catch (error) {
            console.log(error)
            return errorHandler({
                code: 1
            })
        }
    };

    let res = await request(url, options);

    return res;

}

export default fetch;

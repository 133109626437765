import fetch from '../utils/fetch';


export const getOrder = (params:any) => {
    return fetch({
        method: 'GET',
        url: '/h5/user/order',
        data: params
    })
}


export const addOrder = (params:any) => {
    return fetch({
        method: 'POST',
        url: '/h5/order',
        data: params
    })
}



export const orderDetail = (params:any) => {
    return fetch({
        method: 'GET',
        url: `/h5/user/order/${params.id}`
    })
}

export const alipay = (params:any) => {
    return fetch({
        method: 'POST',
        url: '/h5/order/pay/alipay',
        data: params
    })
}

export const wxpay = (params:any) => {
    return fetch({
        method: 'POST',
        url: '/h5/order/pay/wxpay',
        data: params
    })
}


export const closeOrder = (params:any) => {
    return fetch({
        method: 'PATCH',
        url: `/h5/user/order/${params.id}/close`,
    })
}

export const confirmOrder = (params:any) => {
    return fetch({
        method: 'PATCH',
        url: `/h5/user/order/${params.id}/confirm-receipt`,
    })
}

export const refundInvoiceOrder = (params:any) => {
    return fetch({
        method: 'PATCH',
        url: `/h5/user/order/${params.id}/invoice/refund`,
    })
}

export const refundOrder = (params:any) => {
    return fetch({
        method: 'PATCH',
        url: `/h5/user/order/${params.id}/refund`,
        data: params
    })
}



